.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #121212;
  border-radius: 0.5rem;
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalTitle {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #2d2d2d;
}

.modalContent {
  padding: 1rem;
  overflow-y: auto;
  flex-grow: 1;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.searchAndDisplay {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.searchInput {
  flex: 1;
  min-width: 200px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #1e1e1e;
  border: none;
  color: white;
}

.displayControls {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.controlGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #1e1e1e;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.controlSelect {
  background: transparent;
  color: white;
  border: none;
  outline: none;
  padding: 0.25rem;
}

.controlSelect option {
  background: #1e1e1e;
  color: white;
}

.checkbox {
  width: 1rem;
  height: 1rem;
  accent-color: #3b82f6;
}

.sortControls {
  display: flex;
  justify-content: flex-end;
}

.sortSelect {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #1e1e1e;
  border: none;
  color: white;
  min-width: 150px;
}

.filters {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #1e1e1e;
  border-radius: 0.5rem;
}

.filterGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.filterGroup label {
  font-size: 0.875rem;
  color: #a0a0a0;
}

.filterGroup select {
  padding: 0.25rem;
  border-radius: 0.25rem;
  background: #252525;
  border: none;
  color: white;
  min-width: 120px;
}

.partsGrid {
  display: grid;
  gap: 1rem;
  padding: 1rem;
}

.partCard {
  background: #1e1e1e;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  position: relative;
}

.partCard:hover {
  background: #252525;
  transform: translateY(-2px);
}

.imageContainer {
  aspect-ratio: 1;
  padding: 1rem;
  background: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s;
}

.partCard:hover .partImage {
  transform: scale(1.05);
}

.partInfo {
  padding: 1rem;
}

.partName {
  font-weight: 500;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partCategory {
  color: #a0a0a0;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.partPrice {
  color: #10b981;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.starRating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.star {
  color: #666;
  cursor: pointer;
  transition: color 0.2s;
}

.star.filled {
  color: #fbbf24;
}

.ratingInfo {
  font-size: 0.75rem;
  color: #a0a0a0;
  margin-left: 0.5rem;
}

.unavailable {
  opacity: 0.5;
  cursor: not-allowed;
}

.unavailableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.lowestRated {
  border: 2px solid #ef4444;
}

.loadingMore {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
}

.spinner {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  flex-wrap: wrap;
}

.pageButton {
  padding: 0.5rem 1rem;
  background: #1e1e1e;
  border: none;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pageButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageButton:hover:not(:disabled) {
  background: #252525;
}

.activePage {
  background: #3b82f6;
}

.ellipsis {
  padding: 0.5rem;
  color: white;
}

.closeButton {
  padding: 0.75rem;
  background: #1e1e1e;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.closeButton:hover {
  background: #252525;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .modal {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    border-radius: 0;
    margin: 0;
  }

  .modalTitle {
    font-size: 1.25rem;
    padding: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #121212;
    z-index: 20;
    border-bottom: 1px solid #2d2d2d;
  }

  .modalContent {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    height: calc(100vh - 7rem);
    overflow-y: auto;
    padding: 0;
  }

  .controls {
    background: #121212;
    padding: 0.5rem;
    border-bottom: 1px solid #2d2d2d;
  }

  .searchAndDisplay {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  .searchInput {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .displayControls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .controlGroup {
    padding: 0.75rem;
    justify-content: space-between;
  }

  .controlGroup:last-child {
    grid-column: 1 / -1;
    justify-content: center;
  }

  .controlSelect, .sortSelect {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .filters {
    flex-direction: column;
    padding: 0.75rem;
    gap: 0.75rem;
    margin: 0;
    border-bottom: 1px solid #2d2d2d;
  }

  .filterGroup select {
    padding: 0.75rem;
    font-size: 0.875rem;
    width: 100%;
  }

  .partsGrid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .partCard {
    border-radius: 0.375rem;
  }

  .imageContainer {
    padding: 0.5rem;
  }

  .partInfo {
    padding: 0.75rem;
  }

  .partName {
    font-size: 0.875rem;
  }

  .partCategory {
    font-size: 0.75rem;
  }

  .partPrice {
    font-size: 1rem;
  }

  .starRating {
    font-size: 0.875rem;
  }

  .pagination {
    position: fixed;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0.75rem;
    z-index: 20;
  }

  /* Only show background when pagination is visible and not empty */
  .pagination:not(:empty) {
    background: #121212;
    border-top: 1px solid #2d2d2d;
  }

  .closeButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0;
    z-index: 20;
    background: #1e1e1e;
  }
}
