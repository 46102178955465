@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-color: #121212;
  color: #ffffff;
}

/* Search overlay styles */
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  backdrop-filter: blur(4px);
}

/* Search input container */
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(30, 30, 30, 0.98);
  border-bottom: 1px solid #2d2d2d;
  z-index: 41;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.search-input {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 768px) {
  /* Mobile menu styles */
  .mobile-nav {
    position: fixed;
    top: 60px; /* Height of header */
    right: 0;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    z-index: 50;
    background-color: #1e1e1e;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    border-left: 1px solid #2d2d2d;
  }
  
  .mobile-nav.open {
    transform: translateX(0);
  }
}

.header {
  background-color: #1e1e1e;
  border-bottom: 1px solid #2d2d2d;
}

.nav-link {
  color: #a0aec0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.nav-link:hover {
  color: #ffffff;
}
