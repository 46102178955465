.container {
  overflow-y: auto;
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: 100%;
}

.navigationButtons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.navButton {
  background-color: #1e1e1e;
  border: 1px solid #2d2d2d;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s;
}

.navButton:hover {
  background-color: #2d2d2d;
  transform: scale(1.05);
}

.activeNavButton {
  background-color: #4CAF50;
}

.navIcon {
  width: 24px;
  height: 24px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #1e1e1e;
  border: 1px solid #2d2d2d;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.closeButton:hover {
  background-color: #2d2d2d;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb {
  background: #2d2d2d;
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #3d3d3d;
}

.container {
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d #1e1e1e;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #2d2d2d;
}

.section {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.sectionTitle {
  color: #4CAF50;
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 18px;
}

.controlGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.slider {
  width: 100%;
  background: #2d2d2d;
  height: 5px;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  -webkit-appearance: none;
  appearance: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.value {
  margin-left: 10px;
  color: #a0aec0;
}

.colorPicker {
  width: 50px;
  height: 30px;
  border: 1px solid #2d2d2d;
  outline: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
}

.axisControl {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.axisLabel {
  width: 20px;
  margin-right: 10px;
  color: #a0aec0;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  padding: 10px 15px;
  border: 1px solid #2d2d2d;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.button:hover {
  background-color: #2d2d2d;
}

.activeButton {
  background-color: #4CAF50;
  color: #ffffff;
}

.customSelect {
  position: relative;
  width: 100%;
}

.select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background-color: #1e1e1e;
  border: 1px solid #2d2d2d;
  color: #ffffff;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

.selectOverlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #1e1e1e;
  border: 1px solid #2d2d2d;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: none;
}

.customSelect:focus-within .selectOverlay {
  display: block;
}

.optionWithImage {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.optionWithImage:hover {
  background-color: #2d2d2d;
}

.optionImage {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  object-fit: contain;
}

.compatibilityList {
  list-style-type: none;
  padding-left: 0;
}

.compatibilityList li {
  margin-bottom: 5px;
  color: #ff6b6b;
}
